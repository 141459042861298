<template>
  <div class="detail-prop-book-proceed">
    <div class="wrapper">
      <div class="layer">
        <a href="#" @click="$router.go(-1)" class="bck cvr-bg-bf">Back</a>
        <div class="due">
          <b>Make Payment Before</b>
          <strong>
            {{ timeLimit }}
          </strong>
        </div>
        <!-- end of due -->
        <table>
          <tr>
            <td>
              <label>Payment Method</label>
              <b>Bank Account</b>
            </td>
            <td>
              <label>Bank</label>
              <b>
                {{ paymentInfo.va_numbers[0].bank }}
              </b>
            </td>
            <td>
              <label>
                Virtual Account Number
                <a href="#" @click="copyVA">Copy</a>
              </label>
              <b>
                {{ paymentInfo.va_numbers[0].va_number }}
              </b>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <label>Total Payment</label>
              <strong
                >Rp {{ $store.state.checkout.amount | convertToRupiah }}
              </strong>
            </td>
          </tr>
        </table>

        <div class="txt">
          <p>
            Please email your transfer proof to
            <a href="mailto:admin@rubiiproperty.com" target="_blank">
              admin@rubiiproperty.com.
            </a>
            Once confirmed, your status in My Transactions will be automatically
            updated. Until such confirmation, this booking should not be deemed
            definitive.
          </p>
        </div>
        <router-link
          :to="`/account/transaction/${$store.state.checkout.transactionId}`"
          class="button"
        >
          Show Booking Details
        </router-link>
      </div>
      <!-- end of layer -->
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of detail prop book-->
</template>

<script>
import moment from "moment";

export default {
  computed: {
    paymentInfo() {
      return JSON.parse(this.$store.state.checkout.midtransInfo);
    },
    timeLimit() {
      return moment(this.paymentInfo.transaction_time)
        .add(1, "day")
        .format("DD MMMM YY HH:mm");
    }
  },
  methods: {
    copyVA() {
      navigator.clipboard.write(this.paymentInfo.va_numbers[0].va_number);
      this.$swal({
        icon: "success",
        text: "Virtual Account Number Copied"
      });
    }
  }
};
</script>
